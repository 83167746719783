const routes = [
    {
        path: '',
        redirect: '/analysis'
    },
    {
        name: 'test',
        path: '/test',
        component: () => import('@/pages/test.vue'),
        meta: {
            title: 'test',
            requireAuth: true
        }
    },
    {
        name: 'account',
        path: '/account',
        component: () => import('@/pages/account.vue'),
        meta: {
            title: '用户管理',
            requireAuth: true
        }
    },
    {
        name: 'fahuoshuju',
        path: '/fahuoshuju',
        component: () => import('@/pages/fahuoshuju.vue'),
        meta: {
            title: '发货数据',
            requireAuth: true
        }
    },
    {
        name: 'huikuanshuju',
        path: '/huikuanshuju',
        component: () => import('@/pages/huikuanshuju.vue'),
        meta: {
            title: '回款数据',
            requireAuth: true
        }
    },
    {
        name: 'fahuohuikuanpipei',
        path: '/fahuohuikuanpipei',
        component: () => import('@/pages/fahuohuikuanpipei.vue'),
        meta: {
            title: '发货回款匹配',
            requireAuth: true
        }
    },
    {
        name: 'huikuanshuju_pipei_success',
        path: '/huikuanshuju_pipei_success',
        component: () => import('@/pages/huikuanshuju_pipei_success.vue'),
        meta: {
            title: '回款匹配成功',
            requireAuth: true
        }
    },
    {
        name: 'fahuoshuju_pipei_success',
        path: '/fahuoshuju_pipei_success',
        component: () => import('@/pages/fahuoshuju_pipei_success.vue'),
        meta: {
            title: '发货匹配成功',
            requireAuth: true
        }
    },
    {
        name: 'analysis',
        path: '/analysis',
        component: () => import('@/pages/analysis.vue'),
        meta: {
            title: '数据看板',
            requireAuth: true
        }
    }
];
export default routes